interface CookieStorage {
  set<T>(key: string, value: T, options?: CookieOptions): void;
  get<T>(key: string): T | null;
  remove(key: string): void;
}

interface CookieOptions {
  expires?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
}

export const cookie: CookieStorage = {
  set<T>(key: string, value: T, options?: CookieOptions): void {
    let cookieString = `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
    if (options) {
      if (options.expires) {
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() + options.expires);
        cookieString += `; expires=${expires}`;
      }
      if (options.path) cookieString += `; path=${options.path}`;
      if (options.domain) cookieString += `; domain=${options.domain}`;
      if (options.secure) cookieString += '; secure';
      if (options.sameSite) cookieString += `; samesite=${options.sameSite}`;
    }
    document.cookie = cookieString;
  },

  get<T>(key: string): T | null {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    for (const cookie of cookies) {
      const [cookieKey, cookieValue] = cookie.split('=');
      if (decodeURIComponent(cookieKey) === key) {
        return cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : null;
      }
    }
    return null;
  },

  remove(key: string): void {
    document.cookie = `${encodeURIComponent(key)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  },
};
